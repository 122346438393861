import React from 'react';
import EmailUsForm from '../Components/EmailUsForm'

const EmailUsPage = () => {
  return (
    <div>
        <EmailUsForm />
  </div>
       )
};

export default EmailUsPage;
