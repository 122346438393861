import React from 'react'
import Cookies from '../Components/Cookies'
const CookiePage = () => {
    return (
        <div>
           <Cookies /> 
        </div>
    )
}

export default CookiePage
