import React from 'react'
import Cookie from '../img/cookies-svgrepo-com 1.svg'
const Cookies = () => {
    return (
        <div>
            <img src={Cookie} alt="Cookie SVG" />
        </div>
    )
}

export default Cookies
